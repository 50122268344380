import React from "react"
import Layout from "../components/Layout"
import { Container,Grid, Paper, TextField, Typography, MenuItem } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"




const useStyles = makeStyles(theme => ({
  root:{
    marginTop: "110px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "85px",
    },
  },
  formContainer: {   
  
       
    padding:theme.spacing(2),     
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flexDirection:'row',
    flexWrap:'wrap',
    width:'100%',

  },
  label:{
    position:'relative',
    width:'110px',
    color:'#5D5D5D',
    fontWeight:700,
    transform:'unset',
    paddingTop:'10px',
    fontSize:'15px'
  },
  labelOutlined:{
    transform:'unset',
  },
  labelShrink:{
    transform:'unset!important',
  },
  inputRoot:{
    flexGrow:1,  
  },
  inputOutlined:{
    padding:'10px',
  },
  formHelperText:{
    marginLeft:'110px'
  },
  paper:{
    padding:'20px',
  }
}))

export default function Porgram(props) {
  const formFields = [
    {name:"nombre", label:"Nombres:",required:true,placeholder:"Juan Pedro"},
    {name:"apellido", label:"Apellidos:", required:true,placeholder:"Rodriguez Perez"},
    {name:"documento", label:"Documento de Identidad:", required:true,placeholder:"12345670"},    
    {name:"pais",label:"Pais",required:true, options:['a','b','c'], placeholder:"Seleccione"},
    {name:"ciudad",label:"Ciudad:",required:true,placeholder:"Montevideo"},    
    {name:"direccion",label:"Domicilio:", placeholder:"Calle 1234 apto. 456 esq. Otra"},
    {name:"telefono",label:"Telefono:",required:true,placeholder:"099123456"},
    {name:"mail",label:"E-Mail:",required:true,placeholder:"juan.perez@mail.com"},
    {name:"ocupacion",label:"Ocupación:",required:true,placeholder:"Profesor,Docente,Estudiante,etc",selectProps:{multiple:true,},default:[],options:['a','b','c','otros']},
    {name:"sector",label:"Sector",required:true,options:['a','b','c'],}
  ]
 
  const reducer = formFields.reduce((acc,cur)=>({...acc, [cur.name]:{val:cur.default?cur.default:'',error:[]}}),{})
  
  const classes = useStyles()
  
  const [values,setValues] = React.useState(reducer)
  //const [errors,setErrors] = React.useState(reducer)
  //console.log(values)
  

  const validate = field => e => {
    let newState={...values}
   
    let addErr = (errorMsg)=>{
      if(newState[field].error.indexOf(errorMsg)===-1)
        newState[field].error.push(errorMsg)       
    }
    let remErr = (errorMsg)=>{
      let index = newState[field].error.indexOf(errorMsg)
      if(index !==-1)
        newState[field].error.splice(index,1)
    }
    switch(field){
      case'nombre':               
        let noVacio="No puede ser vacio"
        e.target.value === ''?addErr(noVacio):remErr(noVacio)        
        e.target.value.length<2?addErr("Nombre debe tener mas de 1 caracter"):remErr("Nombre debe tener mas de 1 caracter")
        let regexp = /^[a-z A-Z'`\-áéíóúöëÁÉÍÓÚñÑ]+$/
        regexp.test(e.target.value)?remErr("Formato inválido. Caracteres permitidos: 'a-z', 'A-z', '-', '"):addErr("Formato inválido. Caracteres permitidos: 'a-z', 'A-z', '-', '")
        
        setValues(newState)
         
        break;
      default:
        break;        
    }   
  }
  const handleChange = field => event => {    
      let newState = {...values}
      newState[field].val=event.target.value      
      setValues(newState);   
      
  };

  const renderList = (field) => {
    let ret = field.options?
      field.options.map((value,index)=>(    
        <MenuItem children={value} value={value} key={index}/>
      ))
      :null
    
    return ret
  }
  
  return (
    <Layout>
      <Container className={classes.root} maxWidth="sm">
        <Paper className={classes.paper}>
        <Typography align="center" variant="h4">
            Inscribirse 
          </Typography>

         <form className={classes.formContainer}>
          <Grid container>
         {formFields.map((field)=>
              
              <TextField 
                id={field.name}
                name={field.name}
                label={field.label}
                className={classes.textField}
                required={field.required} 
                value={values[field.name].val}
                onChange={handleChange(field.name)}  
                onBlur={validate(field.name)}
                margin="dense"
                variant="outlined"
                select={typeof field.options==="object"}
                SelectProps={{...field.selectProps}}
                InputProps={{
                  labelWidth:150,
                  notched:false,
                  classes:{
                    root:classes.inputRoot,
                    input:classes.inputOutlined,
                  },
                  
                }}
                
                InputLabelProps={{
                    classes:{
                      formControl: classes.label,
                      outlined: classes.labelOutlined,
                      shrink: classes.labelShrink,
                    },
                    shrink:true,
                    htmlFor:field.name+'-placeholder',
                    children:field.placeholder
                   
                }}
                FormHelperTextProps={{
                  classes:{
                    root:classes.formHelperText,
                  }
                }}
                
                error={values[field.name].error.length!==0}
                helperText={values[field.name].error.join(",\n")}
                placeholder={field.placeholder?field.placeholder:''}
                children={renderList(field)}
                
                {...field.props}
          
              />
             
             
              
         )}
         </Grid>
         </form>
        </Paper>
      </Container>
    </Layout>
  )
}
